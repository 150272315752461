import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import Banner from 'components/usability-testing/banner';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
} from 'components/styled/CustomerPage';
import { media } from 'theme/breakpoints';
import Container from 'components/customer/breadcrumbs';

const RecommendationBox = styled.div`
  & div.recommendation-box {
    margin-bottom: 90px;
    ${media.tablet} {
      margin-bottom: 24px;
    }
  }
`;

const Plaxonic = () => {
  const { hero, asharma, post } = useStaticQuery(graphql`
    query plaxonicPhotos {
      hero: file(relativePath: { eq: "plaxonic/hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      asharma: file(relativePath: { eq: "persons/aditya_sharma.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "Plaxonic" } }) {
        frontmatter {
          description
          title
          slug
          company
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.fluid.src;
  const descriptionSocial = frontmatter.description;
  const { title, slug } = frontmatter;

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + slug },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle="How Plaxonic Saved 25% of Their Team's Time Using LiveSession"
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/plaxonic/"
      image={hero.childImageSharp.fluid.src}
    >
      <div className="container">
        <Container title="Plaxonic">
          <Header
            title={
              <>
                How Plaxonic Technologies gained <span>25% time savings</span> and recognition from
                many customers, using LiveSession.
              </>
            }
            description="Better service in a shorter time? What more do you need?"
            titleStyle={{ maxWidth: 920 }}
          />
          <HeroImage
            fluid={hero.childImageSharp.fluid}
            clasName="img-fluid"
            fadeIn
            alt="website - hero"
            title="Plaxonic team"
            style={{ maxHeight: 500 }}
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              <p>
                {' '}
                Plaxonic Technologies is a company that provides IT and Business Process Management
                (BPM) solutions for a wide spectrum of customers. It offers many services, such as
                Website Design & Development, Application Development, Artificial Intelligence,
                Blockchain, Digital Transformation, or Data Analytics.{' '}
              </p>
              <p>
                Since its founding in 2013, the company has been striving for consistent improvement
                and client centricity.
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Key achievements</h2>
              <ul>
                <li>
                  <p>
                    <strong>time savings of 25%</strong> for the Plaxonic Technologies team
                  </p>
                </li>
                <li>
                  <p>
                    positive impact on customers by increasing their conversion rate (
                    <strong>up to 18%</strong>)
                  </p>
                </li>
                <li>
                  <p>differentiation of visitors from organic and paid traffic</p>
                </li>
                <li>
                  <p>identification of exit points and loopholes on the customer sites</p>
                </li>
                <li>
                  <p>detection of the most visited sections of the websites</p>
                </li>
                <li>
                  <p>acquisition of statistical data about visitors and clicks</p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>
          <StyledSection>
            <h2>Focus on modern technology</h2>
            <p>
              To be successful in tackling their customers’ problems with software and BPM, the
              company has been observing ongoing trends and analyzing upcoming technologies.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="Our recipe for success is to combine years of our experience with ambitious strategies and advanced digital solutions. LiveSession is an example of advanced technology supporting leaders like us."
            person="Aditya Sharma"
            job="CTO of Plaxonic Technologies"
            company="Plaxonic"
            url="https://plaxonic.com"
            image={asharma.childImageSharp.fluid}
            style={{ marginBottom: 0 }}
          />
          <StyledSection>
            <p>
              Plaxonic Technologies has been applying different methods to discover, model, analyze,
              measure, improve, optimize, and automate the business processes of their clients. For
              this reason, the company has been using many marketing, analytics, and UX tools.
            </p>
            <p>
              The marketing analytics toolbox of Plaxonic Technologies consists of Google Analytics,
              Statcounter, and Google Firebase. On the list of the marketing tools, we may find
              Follow planer, Ahrefs, Zoho, Google Trends, Keyword Planner, Unbounce, and many
              others. The software improving User Experience includes Dreamveaver, Elementor, and
              Divi Builder.
            </p>
            <Banner title="Are you sure that your tech stack is complete?" />
          </StyledSection>

          <StyledSection>
            <h2>The missing piece of the puzzle</h2>
            <p>
              Plaxonic Technologies believes in “inventing advanced technologies & innovating them
              further”. Therefore, in a short time, they realized that they would be more successful
              with a session tracking software. Such a solution would enable the company to make a
              stronger and positive impact in assisting customers to gain profitable business
              growth. It would be much easier to advise improvements to promoted websites knowing
              customer journeys.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Looking for a perfect solution</h2>
            {/* <StyledImg
              fluid={img1.childImageSharp.fluid}
              fadeIn
              alt="Databox app"
              title="Databox business plaform"
              className="img-fluid"
            /> */}
            <p>
              LiveSession allows you to analyze different sections of the webpage separately, while
              other analytics tools only provide aggregated data.
            </p>
            <p>
              Unfortunately, before Plaxonic Technologies discovered the optimal software, they
              tried another solution available on the market. It was not satisfying, so Plaxonic
              Technologies turned to LiveSession for assistance.
            </p>
            <Banner title="Will it be the solution for you or for your customers?" />
            <p>
              LiveSession provided exactly what was needed: live video recordings that showed how
              visitors landed and interacted with websites that Plaxonic Technologies took care of.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Multiple integrations</h2>
            <p>
              Plaxonic Technologies was incredibly happy to be able to connect LiveSession with any
              website so easily.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="The integration of LiveSession with our site took just a few minutes. The same happened with integrations for our customers. That was fantastic!"
            person="Aditya Sharma"
            job="CTO of Plaxonic Technologies"
            company="Plaxonic"
            image={asharma.childImageSharp.fluid}
            style={{ marginBottom: 0 }}
          />
          <StyledSection as="p">
            It was enough to copy and paste a piece of LiveSession’s tracking code. The team
            informed developers about the planned integration with respective websites. They
            provided them with the relevant instructions. Developers completed integration in just
            two clicks. Soon after, Plaxonic Technologies was able to use LiveSession on
            customers&apos; websites.
          </StyledSection>
          <StyledSection>
            <h2>The power of information</h2>
            <p>
              With LiveSession, it is possible to analyze behavior of users coming from different
              traffic sources. Some of them are part of organic traffic coming from SERPs, and some
              of them are part of paid traffic coming from Google Ads or other paid listings such as
              GoodFirms.{' '}
            </p>
            <p>
              Valuable insight can come from knowing which sections of a visited site have been
              interesting to particular groups of users. With this knowledge, Plaxonic Technologies
              chooses where to focus advertising and promotion. Moreover, LiveSession has helped the
              company identify exit points and loopholes on its website and those of its customers.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Ability to draw conclusions</h2>
            <p>
              Observation of numerous customer journeys has given Plaxonic Technologies a lot of
              insight. They understood why and when visitors choose to interact heavily with
              different sections of certain web pages. They also realized why some users decided to
              exit other pages quickly.
            </p>
            <p>
              The information collected through LiveSession included the number of clicks, new
              users&apos; data (i.e., their screen resolution, browser or OS), error clicks, rage
              clicks, and exit points.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>From conclusions to actions</h2>
            <p>
              After a thorough analysis of the data collected and the rate of the traffic growth,
              Plaxonic Technologies was able to offer some valuable enhancements to its customers.
              Mainly there were recommendations of UX and web design improvements. Sometimes,
              changing the CTA buttons was enough. This was advised to GroutGroovy.
            </p>
            <p>
              In other cases, the recommendation was to make significant design changes. For
              example, in the case of HerbalCart, data collected by LiveSession provided insight
              into what visitors wanted to look at. With this knowledge, Plaxonic Technologies
              suggested the client redesign the entire site. HerbalCart agreed, and this decision
              paid off by resulting in an 18% increase in conversion rates.
            </p>
          </StyledSection>
          <RecommendationBox>
            <Recommendation
              recommendation="Convincing a client to make a significant and expensive change is nearly impossible without data to support the underlying rationale. With LiveSession, we dare our customers to make brave decisions that pay off quickly."
              person="Aditya Sharma"
              job="CTO of Plaxonic Technologies"
              company="Plaxonic"
              image={asharma.childImageSharp.fluid}
            />
          </RecommendationBox>
          <FinalOutcome lastSection>
            <h2>Final outcome</h2>
            <p>
              Plaxonic Technologies uses LiveSession mainly to help its customers, but the company
              also benefits from this solution. It was possible to automate some of the team’s work
              and since then employees have been able to spend significantly less time on various
              tasks. This has enabled Plaxonic Technologies to serve more customers at the same time
              and to increase the company’s efficiency.
            </p>
            <Banner title="Is your agency aspiring to get more for less?" />
            <p>
              With LiveSession, Plaxonic Technologies has a greater ability to assist a growing
              number of customers. The company is also more successful in assisting them in meeting
              the business expectations. This has been made possible through an improved UX, changes
              in website designs, and significantly increased conversion rate growth.
            </p>
            <p>In summary, the benefits of LiveSession for Plaxonic Technologies were following:</p>
            <ul>
              <li>
                <p>
                  <strong>25% savings</strong> of team worktime that increased the company’s
                  efficiency
                </p>
              </li>
              <li>
                <p>customer recognition for successfully meeting business requirements</p>
              </li>
              <li>
                <p>
                  <strong>up to 18%</strong> of conversions rate growth for clients served by
                  Plaxonic Technologies
                </p>
              </li>
            </ul>
          </FinalOutcome>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Plaxonic;
